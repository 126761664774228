<template>
  <div>
    <div class="mass-action-settings">
      <div>
        <cards-stage-select
          v-model="actionData.stage"
          size="small"
        ></cards-stage-select>
      </div>

      <div style="margin-top: 10px;">
        <el-checkbox v-model="actionData.create_stage_if_not_exist">Создать этап, если не существует</el-checkbox>
      </div>

      <div style="margin-top: 20px;">
        <el-popconfirm
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите начать этап?"
          @confirm="doAction"
        >
          <el-button
            slot="reference"
            size="small"
            type="primary"
            :disabled="!actionData.stage"
          >
            Запустить
          </el-button>

        </el-popconfirm>
      </div>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";

export default {
  name: "card-end-stage-action",
  mixins: [ActionTemplateMixin],
  components: {MassActionResult, CardsStageSelect},

  data() {
    return {
      actionData: {
        stage: null,
        create_stage_if_not_exist: false,
      }
    }
  },
  methods: {},
}
</script>
